import React from "react";
import { useGoogleLogin } from "react-google-login";

// refresh token
import { refreshTokenSetup } from "./refreshToken";

const clientId = process.env.REACT_APP_GOOGLE_ID || "";

export default function LoginHooks() {
  const onSuccess = (res: any) => {
    refreshTokenSetup(res);
    localStorage.setItem("authToken", res.tokenId);
    localStorage.setItem("email", res.profileObj.email);
    window.location.reload();
  };

  const onFailure = (res: any) => {
    console.log("Login failed: res:", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: "offline",
    // responseType: 'code',
    // prompt: 'consent',
  });

  return (
    <button onClick={signIn} className="button">
      <span className="buttonText">Sign in with Google</span>
    </button>
  );
}
