import LoginPage from "../Components/Auth/LoginPage";
import Header from "../Components/Header/Header";
import FileUploader from "../Components/FileUploader/FileUploader";
import { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

export default function MoratoriumTable() {
  const authenticated = localStorage.getItem("authToken");
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const [currentData, setCurrentData] = useState<any[]>([]);
  const [dataIssues, setDataIssues] = useState<any[]>([]);
  const [validated, setValidated] = useState(false);
  const [city, setCity] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(false);
  const [resultUrl, setResultUrl] = useState("");
  const [done, setDone] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [gridApi, setGridApi] = useState<any>();
  const [filter, setFilter] = useState({});
  const [errors, setErrors] = useState("");
  const [s3Link, setS3Link] = useState("");

  const prohibitedStreetNames = ["end", "city limits", "cul"];

  useEffect(() => {
    if (gridApi) {
      gridApi.setFilterModel(filter);
    }
  }, [filter, gridApi]);

  const ignoreErrors = () => {
    setValidated(true);
  };

  const validateData = () => {
    let issues: any[] = [];
    currentData.forEach((row, i) => {
      const currRow = i + 1;
      const rowString = JSON.stringify(row).toLowerCase();
      prohibitedStreetNames.forEach((name) => {
        if (rowString.indexOf(name) > -1) {
          issues.push(
            <>
              <button
                onClick={() => {
                  setFilter({
                    row: { type: "equals", filter: currRow },
                  });
                }}
              >
                {name} found in row {currRow}
              </button>
            </>
          );
        }
      });
    });
    setDataIssues(issues);
    if (issues.length === 0) {
      setValidated(true);
    }
  };

  const handleGeocode = async () => {
    if (city.length > 1) {
      setWaiting(true);
      fetch(process.env.REACT_APP_SERVER + "/moratoriumapp", {
        method: "POST",
        body: JSON.stringify({ data: currentData, city: city }),
      })
        .then((res) => {
          return res.json();
        })
        .then((resobj) => {
          setResultUrl(
            "https://geojson.io/#data=data:application/json," +
              JSON.stringify(resobj.geojson)
          );
          setErrors(JSON.stringify(resobj.errors));
          setS3Link(resobj.file_url);
          setDone(true);
        })
        .catch((e) => {
          console.log(e);
          setError(true);
        });
    }
  };

  const columnDefs = [
    { field: "row", initialWidth: 100 },
    { field: "streetname" },
    { field: "from" },
    { field: "to" },
    { field: "detail" },
    { field: "start" },
    { field: "expiration" },
    { field: "flag" },
  ];

  const onCellValueChanged = (api: any) => {
    let nodes: any[] = [];
    api.api.forEachNode((rowNode: any) => {
      nodes.push(rowNode.data);
    });
    setCurrentData(nodes);
  };

  return authenticated ? (
    <>
      <main className="h-screen">
        <Header showFilter={false} />

        {uploadError ? (
          <>
            <div
              style={{
                textAlign: "center",
                top: "100px",
                position: "relative",
              }}
            >
              <b>Error uploading file. Check format.</b>
            </div>
          </>
        ) : (
          ""
        )}

        {uploadedData.length > 0 ? (
          <>
            <div
              className="ag-theme-alpine"
              style={{ width: "100%", height: "50%" }}
            >
              <AgGridReact
                rowData={uploadedData}
                pagination={true}
                columnDefs={columnDefs}
                onFirstDataRendered={(params) => {
                  params.api.sizeColumnsToFit();

                  let nodes: any[] = [];
                  params.api.forEachNode((rowNode) => {
                    nodes.push(rowNode.data);
                  });
                  setCurrentData(nodes);
                }}
                defaultColDef={{
                  filter: true,
                  sortable: true,
                  resizable: true,
                  editable: true,
                }}
                onCellValueChanged={onCellValueChanged}
                onGridReady={(params) => {
                  setGridApi(params.api);
                }}
              />
            </div>
            <div style={{ top: "50%", height: "40%", margin: "30px" }}>
              {done ? (
                <>
                  <p>
                    View your data{" "}
                    <button
                      style={{ color: "blue" }}
                      onClick={() => {
                        window.open(resultUrl);
                      }}
                    >
                      here
                    </button>
                    .
                  </p>
                  <p>
                    Download GeoJSON{" "}
                    <button
                      style={{ color: "blue" }}
                      onClick={() => {
                        window.open(s3Link);
                      }}
                    >
                      here
                    </button>
                    .
                  </p>
                  <p>{errors.length > 0 ? "Errors: " + errors : ""}</p>
                </>
              ) : error ? (
                <>There was an error processing this data.</>
              ) : waiting ? (
                <>Your file is processing. This may take a few minutes</>
              ) : validated ? (
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <button
                      className="rounded-full bg-sky-500 text-white text-lg form-control"
                      style={{ width: "150px" }}
                      onClick={handleGeocode}
                    >
                      Geocode Data
                    </button>
                    <div style={{ padding: 5, width: 300 }}>
                      <input
                        className="shadow appearance-none borderrounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="city"
                        placeholder="Enter city name"
                        onChange={(event) => {
                          setCity(event.target.value);
                        }}
                        required
                      ></input>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <button
                    className="rounded-full bg-sky-500 text-white text-lg"
                    style={{ width: "150px" }}
                    onClick={validateData}
                  >
                    Validate Data
                  </button>
                  {"   "}
                  <button
                    className="rounded-full bg-orange-500 text-white text-lg"
                    style={{ width: "150px" }}
                    onClick={() => {
                      gridApi.setFilterModel({});
                    }}
                  >
                    Clear Filter
                  </button>

                  <button
                    className="rounded-full bg-red-500 text-white text-lg"
                    style={{
                      width: "150px",
                      right: "200px",
                      position: "absolute",
                    }}
                    onClick={ignoreErrors}
                  >
                    Ignore Errors
                  </button>
                </>
              )}
              <button
                className="rounded-full bg-gray-500 text-white text-lg"
                style={{
                  width: "150px",
                  right: "40px",
                  position: "absolute",
                }}
                onClick={() => {
                  gridApi.exportDataAsCsv();
                }}
              >
                Download CSV
              </button>
              {dataIssues.length > 0 ? (
                <p>
                  <b>Fix the following issues:</b>
                </p>
              ) : (
                ""
              )}
              {dataIssues.length > 0
                ? dataIssues.map((row, i) => {
                    return <div key={i}> {row} </div>;
                  })
                : ""}
            </div>
          </>
        ) : (
          <div
            style={{
              margin: "auto",
              top: "50%",
              transform: "translate(-50%, -50%)",
              left: "50%",
              position: "absolute",
            }}
          >
            <FileUploader
              setUploadedData={setUploadedData}
              setUploadError={setUploadError}
            />
          </div>
        )}
      </main>
    </>
  ) : (
    <LoginPage />
  );
}
