import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const axios = require("axios").default;

export default function HomeTable(props: any) {
  const [cols, setCols] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<any>();
  const paving = props.view === "paving";

  const currentUser = localStorage.getItem("email");

  const paving_type_def = {
    field: "paving_type_def",
    initialWidth: 130,
    valueGetter: (params: any) =>
      params.data.type === "Moratoriums" ||
      params.data.type === "Paving Projects",
    hide: true,
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_SERVER).then((res: any) => {
      setCols(res.data.cols);
      setData(res.data.data);
    });
  }, []);

  //update filters
  useEffect(() => {
    if (gridApi) {
      gridApi.setFilterModel(props.filter);
    }
  }, [props.filter, gridApi]);

  useEffect(() => {
    if (gridApi && props.download) {
      gridApi.exportDataAsCsv();
    }
  }, [props.download, gridApi]);

  const shownColumns = paving
    ? [
        "id",
        "agencyname",
        "type",
        "published",
        "population",
        "geo",
        "pdf_link",
        "pdf_source_date",
        "pdf_notes",
      ]
    : [
        "id",
        "agencyname",
        "data_source",
        "type",
        "published",
        "backlogged",
        "X8_Layers",
        "processed",
        "processing",
        "reporting_status",
        "discarded",
        "population",
        "geo",
        "count_email_contacts",
        "pdf_link",
        "outreach_refresh",
      ];

  let columnsDefs = cols
    .filter(({ field }) => shownColumns.includes(field))
    .map(({ field }) => {
      switch (field) {
        case "published":
          return {
            field: "published",
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: ["True", ""],
            },
            initialWidth: 110,
          };
        case "discarded":
          return {
            field: "discarded",
            hide: true,
          };
        case "state":
          return {
            field: "state",
            initialWidth: 90,
          };
        case "id":
          return {
            field: "id",
            initialWidth: 90,
          };
        case "geo":
          return {
            field: "geo",
            initialWidth: 90,
          };
        case "outreach_refresh":
          return {
            field: "outreach_refresh",
            initialWidth: 90,
            editable: true,
          };
        case "type":
          return {
            field: "type",
            initialWidth: 130,
          };
        case "paving_type":
          return paving_type_def;
        case "pdf_link":
          return {
            field: "pdf_link",
            headerName: "PDF Link",
            initialWidth: 130,
            editable: true,
          };
        case "pdf_source_date":
          return {
            field: "pdf_source_date",
            headerName: "PDF Source Date",
            initialWidth: 130,
            editable: true,
          };
        case "pdf_notes":
          return {
            field: "pdf_notes",
            headerName: "PDF Notes",
            editable: true,
          };
        case "reporting_status":
          return {
            field: "reporting_status",
            headerName: "Status",
            initialWidth: 110,
          };
        case "backlogged":
          return {
            field: "backlogged",
            hide: true,
          };
        case "X8_Layers":
          return {
            field: "X8_Layers",
            headerName: "X8 Layers",
            filter: "agNumberColumnFilter",
            initialWidth: 110,
          };
        case "processed":
          return {
            field: "processed",
            valueFormatter: (params: any) => {
              return Math.round(params.value) + "%";
            },
            filter: "agNumberColumnFilter",
            initialWidth: 110,
          };
        case "processing":
          return {
            field: "processing",
            headerName: "In Process",
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: ["True", ""],
            },
            initialWidth: 110,
          };
        case "data_source":
          return {
            field: "data_source",
            headerName: "Source",
            editable: true,
            initialWidth: 150,
          };

        case "outreach":
          return {
            field: "outreach",
            headerName: "Outreach Notes",
            editable: true,
          };
        case "agencyname":
          return {
            field: "agencyname",
            headerName: "Agency",
            pinned: "left",
            cellRenderer: (params: any) => {
              return `${params.data.agencyname}, ${params.data.state}`;
            },
          };
        case "population":
          return {
            field: "population",
            initialWidth: 110,
            filter: "agNumberColumnFilter",
            valueFormatter: (params: any) => {
              const v = params.value;
              if (v && v > 1e6) {
                return Math.round(v / 1e5) / 10 + "M";
              } else if (v) {
                return Math.round(v / 1e3) + "K";
              } else {
                return "";
              }
            },
          };
        case "count_email_contacts":
          return {
            field: "Contacts",
            cellStyle: { textAlign: "center" },
            filter: "agNumberColumnFilter",
            cellRenderer: (params: any) => {
              if (params.data.count_email_contacts > 0) {
                return '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /> </svg>';
              } else {
                return "";
              }
            },
            valueGetter: (params: any) => {
              if (params.data.count_email_contacts > 0) {
                return 1;
              } else {
                return 0;
              }
            },
            flex: 1,
            initialWidth: 110,
          };
        default:
          return {
            type: "string",
            field: field,
          };
      }
    });

  const onCellValueChanged = (event: any) => {
    const col = event.colDef.field;
    if (col === "outreach") {
      const data = {
        cg_id: event.data.id,
        comment: event.newValue,
        user: currentUser,
      };
      fetch(process.env.REACT_APP_SERVER + "/citygrid/appendcomment", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then(() => {
          props.handleAlert("Data updated successfully.");
        })
        .catch(() => {
          props.handleAlert("Error updating data", "red");
        });
    } else if (col === "assigned") {
      const data = {
        person: event.newValue,
        cg_id: event.data.id,
        table: "assignments",
        user: currentUser,
      };
      fetch(process.env.REACT_APP_SERVER + "/citygrid/edit/assignment", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then(() => {
          props.handleAlert("Data updated successfully.");
        })
        .catch(() => {
          props.handleAlert("Error updating data", "red");
        });
    } else if (
      col === "data_source" ||
      col === "pdf_link" ||
      col === "pdf_source_date" ||
      col === "pdf_notes" ||
      col === "outreach_refresh"
    ) {
      const data = {
        id: event.data.id,
        col: event.column.colId,
        value: event.newValue,
        table: "citygrid",
        user: currentUser,
      };
      fetch(process.env.REACT_APP_SERVER + "/citygrid/edit", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            props.handleAlert("Error updating data", "red");
          } else {
            props.handleAlert("Data updated successfully.");
          }
        })
        .catch(() => {
          props.handleAlert("Error updating data", "red");
        });
    } else if (col === "published") {
      if (event.value === "True") {
        fetch(
          process.env.REACT_APP_SERVER + "/citygrid/publish/" + event.data.id,
          { method: "POST", body: JSON.stringify({ user: currentUser }) }
        )
          .then(() => {
            props.handleAlert("Data updated successfully.");
          })
          .catch(() => {
            props.handleAlert("Error updating data", "red");
          });
      } else if (event.value === "") {
        fetch(
          process.env.REACT_APP_SERVER + "/citygrid/unpublish/" + event.data.id,
          { method: "POST", body: JSON.stringify({ user: currentUser }) }
        )
          .then(() => {
            props.handleAlert("Data updated successfully.");
          })
          .catch(() => {
            props.handleAlert("Error updating data", "red");
          });
      }
    } else if (col === "processing") {
      if (event.value === "True") {
        fetch(
          process.env.REACT_APP_SERVER +
            "/citygrid/inprogress/" +
            event.data.id,
          { method: "POST", body: JSON.stringify({ user: currentUser }) }
        )
          .then(() => {
            props.handleAlert("Data updated successfully.");
          })
          .catch(() => {
            props.handleAlert("Error updating data", "red");
          });
      } else if (event.value === "") {
        fetch(
          process.env.REACT_APP_SERVER +
            "/citygrid/unprogress/" +
            event.data.id,
          { method: "POST", body: JSON.stringify({ user: currentUser }) }
        )
          .then(() => {
            props.handleAlert("Data updated successfully.");
          })
          .catch(() => {
            props.handleAlert("Error updating data", "red");
          });
      }
    }
  };

  const onCellClicked = (event: any) => {
    const col = event.colDef.field;
    if (col === "agencyname") {
      window.open("/citygrid/" + event.data.id);
    } else if (col === "Contacts") {
      fetch(process.env.REACT_APP_SERVER + "/contacts/" + event.data.id)
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.length > 0) {
            props.handleModal(json);
          } else {
            props.handleAlert("No contacts", "red");
          }
        })
        .catch((e: any) => {
          props.handleAlert("Error retrieving contacts");
        });
    } else if (col === "id" && event.data.pdf_link) {
      window.open(event.data.pdf_link);
    } else if (col === "population") {
      window.open(
        process.env.REACT_APP_SERVER + "/coveragematrix/" + event.data.id
      );
    }
  };

  return (
    <div style={{ height: "calc(100% - 64px)" }}>
      <div className="ag-theme-alpine h-full">
        <AgGridReact
          rowData={data}
          pagination={true}
          overlayNoRowsTemplate="Loading..."
          columnDefs={
            paving ? columnsDefs.concat(paving_type_def) : columnsDefs
          }
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit();
            if (paving) {
              gridApi.setFilterModel({
                geo: { type: "contains", filter: "Northern California" },
                paving_type_def: { type: "equals", filter: true },
              });
            }
          }}
          onCellValueChanged={onCellValueChanged}
          defaultColDef={{
            filter: true,
            sortable: true,
            resizable: true,
          }}
          onCellClicked={onCellClicked}
          onGridReady={(params) => {
            setGridApi(params.api);
          }}
        ></AgGridReact>
      </div>
    </div>
  );
}
