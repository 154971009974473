import LoginPage from "../Components/Auth/LoginPage";
import Header from "../Components/Header/Header";
import StatusTable from "../Components/Tables/StatusTable";
import { useEffect, useState } from "react";

export default function Home() {
  const authenticated = localStorage.getItem("authToken");
  const [cols, setCols] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_SERVER + "/statusmonitor")
      .then((res: any) => {
        return res.json();
      })
      .then((resjson: any) => {
        setCols(resjson.cols);
        setData(resjson.data);
      });
  }, []);

  return authenticated ? (
    <>
      <main className="h-screen">
        <Header />
        <StatusTable cols={cols} data={data} />
      </main>
    </>
  ) : (
    <LoginPage />
  );
}
