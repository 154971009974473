import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useEffect, useState } from "react";

export default function StatusTable(props: any) {
  const [columnDefs, setColumnDefs] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    const shownColumns = [
      "cg_id",
      "agencyname",
      "type",
      "last_refresh",
      "updates_pending",
      "since_update",
    ];
    if (props.cols) {
      setColumnDefs(
        props.cols
          .filter(({ field }: any) => shownColumns.includes(field))
          .map(({ field }: any) => {
            switch (field) {
              default:
                return {
                  type: "string",
                  field: field,
                };
            }
          })
      );
    }
  }, [props.cols]);

  const onCellClicked = (event: any) => {
    window.open("/citygrid/" + event.data.cg_id);
  };

  return (
    <div style={{ height: "calc(100% - 64px)", width: "100%" }}>
      <div className="ag-theme-alpine h-full">
        <AgGridReact
          rowData={data}
          pagination={true}
          columnDefs={columnDefs}
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit();
          }}
          defaultColDef={{
            filter: true,
            sortable: true,
            resizable: true,
          }}
          onCellClicked={onCellClicked}
        ></AgGridReact>
      </div>
    </div>
  );
}
