import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import PropertyModal from "../Modal/PropertyModal";
import React, { useState } from "react";

export default function DatasetTable(props: any) {
  const currentUser = localStorage.getItem("email");
  const [propsModalContent, setPropsModalContent] = useState([]);

  const shownColumns = [
    "name",
    "prop_count",
    "feature_count",
    "layer_url",
    "comments",
    "status",
  ];

  const getPropertiesfromRestService = (url: string) => {
    fetch(`${url}?f=json`)
      .then((response) => response.json())
      .then((data) => {
        setPropsModalContent(data.fields);
      });
  };

  let columnsDefs = props.cols
    .filter(({ field }: any) => shownColumns.includes(field))
    .map(({ field }: any) => {
      switch (field) {
        case "comments":
          return {
            field: "comments",
            editable: true,
          };
        case "prop_count":
          return {
            field: "prop_count",
            editable: false,
            headerName: "# Properties",
            filter: "agNumberColumnFilter",
            initialWidth: 150,
          };
        case "feature_count":
          return {
            field: "feature_count",
            editable: false,
            headerName: "# Features",
            filter: "agNumberColumnFilter",
            initialWidth: 150,
          };
        case "layer_url":
          return {
            field: "layer_url",
            headerName: "REST URL",
          };
        case "status":
          return {
            field: "status",
            editable: true,
            initialWidth: 150,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: [
                "Backlog",
                "Discard",
                "In Progress",
                "Check with Muni",
                "Reached Out",
                "Approved",
                "Loaded",
                "",
              ],
            },
          };
        default:
          return {
            type: "string",
            field: field,
          };
      }
    });

  const onCellValueChanged = (event: any) => {
    const col = event.colDef.field;
    if (col === "status" || col === "comments") {
      const data = {
        id: event.data.id,
        col: event.column.colId,
        value: event.newValue,
        table: "layer_detail",
        user: currentUser,
      };
      fetch(process.env.REACT_APP_SERVER + "/citygrid/edit", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            props.handleAlert("Error updating data", "red");
          } else {
            props.handleAlert("Data updated successfully.", "sky");
          }
        })
        .catch(() => {
          props.handleAlert("Error updating data", "red");
        });
    }
  };

  const onCellClicked = (event: any) => {
    const col = event.colDef.field;
    if (col === "layer_url") {
      window.open(event.data.layer_url);
    } else if (col === "name") {
      props.onChange(event.data);
      props.handleAlert(event.data.name + " added to map.", "sky");
    } else if (col === "prop_count") {
      console.log(event.data.layer_url);
      getPropertiesfromRestService(event.data.layer_url);
    }
  };

  return (
    <div
      style={{ height: "calc(100% - " + props.height + "px)", width: "100%" }}
    >
      <div className="ag-theme-alpine h-full">
        <AgGridReact
          rowData={props.data}
          pagination={true}
          columnDefs={columnsDefs}
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit();
          }}
          onCellValueChanged={onCellValueChanged}
          defaultColDef={{
            filter: true,
            sortable: true,
            resizable: true,
          }}
          onCellClicked={onCellClicked}
        ></AgGridReact>
        <PropertyModal content={propsModalContent} />
      </div>
    </div>
  );
}
