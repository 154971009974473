import {
  MapContainer,
  TileLayer,
  LayersControl,
  useMapEvents,
  useMap,
} from "react-leaflet";
import ClusterLayer from "react-esri-leaflet/plugins/ClusterLayer";
import "./LeafletMap.css";
import "leaflet/dist/leaflet.css";
import "esri-leaflet-renderers";
import { useEffect, useState } from "react";
import { Resizable } from "re-resizable";

export default function LeafletMap(props: any) {
  const [layers, setLayers] = useState<any[]>([]);
  useEffect(() => {
    if (props.data) {
      setLayers((layers) => [...layers, props.data]);
    }
  }, [props.data]);

  // Pan to new layer when added
  const NewLayerMoveMap = () => {
    const map = useMap();
    useMapEvents({
      overlayadd: (e: any) => {
        setTimeout(() => {
          const _layers = e.layer._layers;
          const _firstLayerKey = Object.keys(_layers)[0];
          const _firstLayer = _layers[_firstLayerKey];

          try {
            try {
              // point layer
              map.panTo(_firstLayer._latlng);
            } catch {
              // nonpoint layer
              map.panTo(_firstLayer._latlngs[0]);
            }
          } catch (e) {
            console.log(e);
          }
        }, 2000);
      },
    });
    return null;
  };

  return (
    <Resizable
      defaultSize={{
        width: "100%",
        height: props.height,
      }}
      size={{ width: "100%", height: props.height }}
      onResizeStop={(e, direction, ref, d) => {
        props.setHeight(props.height + d.height);
      }}
      enable={{
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      <MapContainer center={[37.75, -122.23]} zoom={7}>
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="OSM">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>

          {layers.map((layer: any) => {
            if (layer.feature_count > 0) {
              return (
                <LayersControl.Overlay name={layer.name} key={Math.random()}>
                  <ClusterLayer
                    url={layer.layer_url}
                    disableClusteringAtZoom={17}
                  />
                </LayersControl.Overlay>
              );
            }
            return null;
          })}
          <NewLayerMoveMap />
        </LayersControl>
      </MapContainer>
    </Resizable>
  );
}
