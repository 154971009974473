import {
  MapContainer,
  TileLayer,
  LayersControl,
  useMapEvents,
  Marker,
  Popup,
  GeoJSON,
} from "react-leaflet";
import { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "../Components/LeafletMap/LeafletMap.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [16, 37],
});

L.Marker.prototype.options.icon = DefaultIcon;

function LocationMarker(props: any) {
  const [position, setPosition] = useState([] as any);
  const [notes, setNotes] = useState("");
  const [submitted, setSubmitted] = useState(false);
  useMapEvents({
    click(e) {
      setPosition(e.latlng);
      setSubmitted(false);
      setNotes("");
    },
  });

  return position?.length === 0 ? null : (
    <Marker position={position}>
      <Popup>
        {submitted ? (
          "Note Recorded"
        ) : (
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              await fetch(process.env.REACT_APP_SERVER + "/mapnotesdemo", {
                method: "POST",
                body: JSON.stringify({ notes: notes, position: position }),
              })
                .then(() => {
                  setSubmitted(true);
                  props.handleSubmit();
                })
                .catch();
            }}
          >
            <label>
              Notes:
              <div style={{ border: "1px" }}>
                <textarea
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    borderRadius: "3px",
                    resize: "none",
                  }}
                  name="name"
                  onChange={(event) => setNotes(event.target.value)}
                  value={notes}
                />
              </div>
            </label>

            <input type="submit" value="Submit" />
          </form>
        )}
      </Popup>
    </Marker>
  );
}

export default function MoratoriumTable() {
  const [serverData, setServerData] = useState("");

  const updateServerData = () => {
    fetch(process.env.REACT_APP_SERVER + "/mapnotesdemo")
      .then((res) => {
        return res.json();
      })
      .then((geojson) => {
        setServerData(JSON.stringify(geojson));
      })
      .catch();
  };

  const handleSubmit = () => {
    setTimeout(() => {
      updateServerData();
    }, 500);
  };

  useEffect(() => {
    updateServerData();
  }, []);

  return (
    <>
      <main className="h-screen">
        <MapContainer center={[37.75, -122.23]} zoom={7}>
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="OSM">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay checked name="Notes">
              {serverData && (
                <GeoJSON
                  data={JSON.parse(serverData)}
                  onEachFeature={(feature, layer) => {
                    layer.bindPopup(feature.properties.notes);
                  }}
                />
              )}
            </LayersControl.Overlay>
          </LayersControl>
          <LocationMarker handleSubmit={handleSubmit} />
        </MapContainer>
      </main>
    </>
  );
}
