import LoginHooks from "./LoginHooks";
import "./LoginPage.css";

export default function LoginPage() {
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="apple-touch-icon.png"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Welcome to CivilGrid gistools
            </h2>
            <LoginHooks />
          </div>
        </div>
      </div>
    </>
  );
}
