import { useGoogleLogout } from "react-google-login";
import { LogoutIcon } from "@heroicons/react/outline";

const clientId = process.env.REACT_APP_GOOGLE_ID || "";

export default function LogoutHooks() {
  const onLogoutSuccess = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("email");
    window.location.reload();
  };

  const onFailure = () => {
    console.log("Handle failure cases");
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  return (
    <button onClick={signOut}>
      <LogoutIcon className="h-6 w-6" aria-hidden="true" />
    </button>
  );
}
