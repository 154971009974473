import LoginPage from "../Components/Auth/LoginPage";
import Header from "../Components/Header/Header";
import LeafletMap from "../Components/LeafletMap/LeafletMap";
import DatasetTable from "../Components/Tables/DatasetTable";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Alert from "../Components/Notifications/Notifications";
const axios = require("axios").default;

export default function Dataset() {
  const { id } = useParams();
  const authenticated = localStorage.getItem("authToken");
  const [cols, setCols] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [layerDetails, setLayerDetails] = useState<any[]>([]);
  const [mappedLayers, setMappedLayers] = useState<any[]>([]);
  const [height, setHeight] = useState(300);
  const [alertOn, setAlertOn] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertColor, setAlertColor] = useState("sky");

  const handleChange = (input: any) => {
    setMappedLayers(input);
  };

  const handleAlert = (alert: string, color: string) => {
    setAlertText(alert);
    setAlertOn(true);
    setAlertColor(color);

    setTimeout(() => {
      setAlertOn(false);
    }, 2000);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SERVER + "/citygrid/" + id)
      .then((res: any) => {
        setCols(res.data.cols);
        setData(res.data.data);
        setLayerDetails(res.data.layer_details);
      });
  }, [id]);

  return authenticated ? (
    <>
      <main className="h-screen">
        <Header layerDetails={layerDetails} />

        <Alert
          alertOn={alertOn}
          setAlertOn={setAlertOn}
          alertText={alertText}
          color={alertColor}
        />

        <div style={{ height: "calc(100vh - 64px)" }}>
          <LeafletMap
            data={mappedLayers}
            height={height}
            setHeight={setHeight}
          />
          <DatasetTable
            cols={cols}
            data={data}
            onChange={handleChange}
            height={height}
            handleAlert={handleAlert}
          />
        </div>
      </main>
    </>
  ) : (
    <LoginPage />
  );
}
