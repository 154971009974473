export default function Alert(props: any) {
  const color = props.color || "sky";
  return (
    <>
      {props.alertOn ? (
        <div
          className={
            "text-white px-6 py-4 border-0 rounded absolute mx-20 mb-4 bg-" +
            color +
            "-500 top-30 z-50 opacity-70"
          }
        >
          <span className="inline-block align-middle mr-8">
            {props.alertText}
          </span>
          <button
            className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            onClick={() => props.setAlertOn(false)}
          >
            <span>×</span>
          </button>
        </div>
      ) : null}
    </>
  );
}
