import { FileUploader } from "react-drag-drop-files";
import * as Papa from "papaparse";

const fileTypes = ["CSV"];

function DragDrop(props: any) {
  const handleChange = (file: any) => {
    let formattedResults: any[] = [];
    Papa.parse(file, {
      complete: function (results: any) {
        if (
          JSON.stringify(results.data[0]) ===
          JSON.stringify([
            "streetname",
            "from",
            "to",
            "detail",
            "start",
            "expiration",
          ])
        ) {
          results.data.forEach((r: any, i: number) => {
            if (i > 0 && r.length > 1) {
              const flagRow =
                (r[1].toLowerCase() + r[2].toLowerCase()).indexOf("end") > -1;
              const thisRow = {
                row: i,
                streetname: r[0],
                from: r[1],
                to: r[2],
                detail: r[3],
                start: r[4],
                expiration: r[5],
                flag: flagRow,
              };
              formattedResults.push(thisRow);
            }
          });
          props.setUploadedData(formattedResults);
        } else {
          props.setUploadError(true);
          console.log("file not in correct format");
        }
      },
    });
  };

  return (
    <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
  );
}

export default DragDrop;
