import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Home from "./Views/Home";
import Dataset from "./Views/Dataset";
import StatusMonitor from "./Views/StatusMonitor";
import MoratoriumApp from "./Views/MoratoriumApp";
import MapNoteDemo from "./Views/MapNoteDemo";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pavingview" element={<Home view="paving" />} />
        <Route path="/citygrid/:id" element={<Dataset />} />
        <Route path="/statusmonitor" element={<StatusMonitor />} />
        <Route path="/moratoriumapp" element={<MoratoriumApp />} />
        <Route path="/mapnotedemo" element={<MapNoteDemo />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
