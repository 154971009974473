import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import LogoutHooks from "../../Components/Auth/LogoutHooks";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

const navigation = [{ name: "Filters", href: "#", current: true }];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Header(props: any) {
  let showFilter = true;
  if (props.showFilter === false) {
    showFilter = false;
  }

  const handleFilter = (filterName: string) => {
    switch (filterName) {
      case "filterToProcess":
        props.handleFilter({
          X8_Layers: { type: "greaterThan", filter: "0" },
          processed: { type: "lessThan", filter: "100" },
          published: { type: "notEqual", filter: "True" },
        });
        break;
      case "filterToLoad":
        props.handleFilter({
          X8_Layers: { type: "greaterThan", filter: "0" },
          processed: { type: "equals", filter: "100" },
          published: { type: "notEqual", filter: "true" },
          discarded: { type: "notEqual", filter: "1" },
          backlogged: { type: "equals", filter: "0" },
          processing: { type: "notEqual", filter: "true" },
        });
        break;
      case "onlyX8":
        props.handleFilter({
          X8_Layers: { type: "greaterThan", filter: "0" },
        });
        break;
      case "acquiredNotPublished":
        props.handleFilter({
          reporting_status: { type: "equals", filter: "Data Acquired" },
          published: { type: "notContains", filter: "true" },
        });
        break;
      case "pavingMora":
        props.handleFilter({
          geo: { type: "contains", filter: "Northern California" },
        });
        break;
      case "clear":
        props.handleFilter({});
        break;
    }
  };

  return (
    <>
      <div>
        <Disclosure as="nav" className="bg-orange-400">
          {({ open }) => (
            <>
              <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <a href="/">
                        <img
                          className="h-8 w-8"
                          src="/apple-touch-icon.png"
                          alt="Workflow"
                        />
                      </a>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => {
                          if (
                            window.location.href.indexOf("citygrid") === -1 &&
                            window.location.href.indexOf("statusmonitor") === -1
                          ) {
                            return (
                              <Menu
                                as="div"
                                className="relative inline-block text-left"
                                key={Math.random()}
                              >
                                {showFilter ? (
                                  <div>
                                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                      Filters
                                      <ChevronDownIcon
                                        className="-mr-1 ml-2 h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </Menu.Button>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="z-50 origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() =>
                                              handleFilter("filterToProcess")
                                            }
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block w-full text-left px-4 py-2 text-sm"
                                            )}
                                          >
                                            To Process
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() =>
                                              handleFilter("filterToLoad")
                                            }
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block w-full text-left px-4 py-2 text-sm"
                                            )}
                                          >
                                            To Load
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() =>
                                              handleFilter("onlyX8")
                                            }
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block w-full text-left px-4 py-2 text-sm"
                                            )}
                                          >
                                            Only X8
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() =>
                                              handleFilter(
                                                "acquiredNotPublished"
                                              )
                                            }
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block w-full text-left px-4 py-2 text-sm"
                                            )}
                                          >
                                            Acquired but not Published
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() =>
                                              handleFilter("pavingMora")
                                            }
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block w-full text-left px-4 py-2 text-sm"
                                            )}
                                          >
                                            Paving and Moratoriums
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() =>
                                              handleFilter("clear")
                                            }
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block w-full text-left px-4 py-2 text-sm"
                                            )}
                                          >
                                            <i>Clear Filters</i>
                                          </button>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            onClick={() => {
                                              props.downloadCSV();
                                            }}
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block w-full text-left px-4 py-2 text-sm"
                                            )}
                                          >
                                            <i>Download csv</i>
                                          </button>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            );
                          } else {
                            return props.layerDetails || "";
                          }
                        })}
                      </div>
                    </div>
                  </div>{" "}
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <LogoutHooks />
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {/* {navigation.map((item) => (
                    <Dropdown key={item.name} />
                  ))} */}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* <main>
            <HomeTable />
        </main> */}
      </div>
    </>
  );
}
