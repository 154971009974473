import LoginPage from "../Components/Auth/LoginPage";
import Header from "../Components/Header/Header";
import HomeTable from "../Components/Tables/HomeTable";
import Modal from "../Components/Modal/Modal";
import Alert from "../Components/Notifications/Notifications";
import { useState } from "react";

export default function Home(props: any) {
  const authenticated = localStorage.getItem("authToken");
  const [modalContacts, setModalContacts] = useState("");
  const [alertOn, setAlertOn] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertColor, setAlertColor] = useState("sky");
  const [filter, setFilter] = useState({});
  const [download, setDownload] = useState(0);

  const handleModal = (content: any) => {
    setModalContacts(content);
  };

  const handleFilter = (childFilter: {}) => {
    setFilter(childFilter);
  };

  const downloadCSV = () => {
    setDownload(download + 1);
  };

  const handleAlert = (alert: string, color: string) => {
    setAlertText(alert);
    setAlertOn(true);
    setAlertColor(color);

    setTimeout(() => {
      setAlertOn(false);
    }, 2000);
  };

  return authenticated ? (
    <>
      <main className="h-screen">
        <Header handleFilter={handleFilter} downloadCSV={downloadCSV} />
        <Alert
          alertOn={alertOn}
          setAlertOn={setAlertOn}
          alertText={alertText}
          color={alertColor}
        />
        <Modal content={modalContacts} />
        <HomeTable
          handleModal={handleModal}
          handleAlert={handleAlert}
          filter={filter}
          download={download}
          view={props.view}
        />
      </main>
    </>
  ) : (
    <LoginPage />
  );
}
